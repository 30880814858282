import styled from 'styled-components';
import Image from '../../assets/images/56.jpeg';
import { breakpoints } from '../../constants/breakpoints';

export const ThirteenWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      padding: 80px 16px 55px;
    }

    .title {
      margin-bottom: 40px;
      text-align: left;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 80px 24px 55px;
    }

    .title {
      margin-bottom: 40px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 36px 90px;
    }

    .title {
      font-size: 27px;
      line-height: 30px;
      margin-bottom: 50px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 100px 54px;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 40px;
    }
  }
`;

export const Container = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  @media ${breakpoints.laptop} {
    flex-direction: row;
  }

  @media ${breakpoints.desktop} {
    position: relative;
    margin: 0 auto;
  }
`;

export const Box = styled.div`
  @media ${breakpoints.mobile} {
    margin-bottom: 40px;
  }

  @media ${breakpoints.tablet} {
    width: 50%;
    padding-left: 0;
    padding-right: 22px;

    &:nth-of-type(2n) {
      padding-right: 0;
      padding-left: 22px;
    }
  }

  @media ${breakpoints.desktop} {
    width: 320px;
    box-sizing: content-box;
    padding-left: 0;
    padding-right: 20px;

    &:nth-of-type(2n) {
      padding-right: 38px;
      padding-left: 20px;
    }
  }
`;

export const ImageWrapper = styled.div`
  @media ${breakpoints.mobile} {
    flex: none;
    width: 100%;
    height: calc((100vw - 30px) * 1.21);
    margin-bottom: 40px;
    border-radius: 10px;
    background: url(${Image}) center no-repeat;
    background-size: cover;
    opacity: 0.91;
    box-shadow: 0 4px 16px rgba(72, 107, 125, 0.15);
  }

  @media ${breakpoints.tablet} {
    width: 420px;
    height: 610px;
    margin-bottom: 50px;
  }

  @media ${breakpoints.laptop} {
    margin-right: 50px;
    margin-bottom: 0;
  }

  @media ${breakpoints.desktop} {
    margin-right: 140px;
  }
`;

export const Wrap = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media ${breakpoints.desktop} {
    justify-content: flex-start;
  }
`;

export const Subtitle = styled.h3`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
    margin-bottom: 16px;

    &:after {
      content: '';
      display: block;
      margin-top: 16px;
      width: 78px;
      height: 4px;
      background: #086ad6;
      mix-blend-mode: normal;
      opacity: 0.4;
    }
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }
`;
