import styled from 'styled-components';
import { Title } from '../../../styledComponents';
import { breakpoints } from '../../../constants/breakpoints';

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 400px;
    background: ${(props) => `url(${props.mobileImage}) no-repeat`};
    background-size: cover;
    border-radius: 4px;
    margin-bottom: 40px;
  }

  @media ${breakpoints.laptop} {
    width: 420px;
    height: 522px;
    background: ${(props) => `url(${props.laptopImage}) no-repeat`};
    background-size: cover;
    margin: 0 50px 0 0;
  }

  @media ${breakpoints.desktop} {
    margin: 0 140px 0 0;
  }
`;

export const SharingBlockWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      padding: 60px 15px 0;
    }

    ${Title} {
      text-align: left;
      margin-bottom: 40px;
    }

    ${Text} {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 48px;
      }
    }

    .btn {
      width: 100%;
      max-width: 330px;
    }

    .collapse-action {
      margin: 16px auto 0;
      display: block;
      padding: 14px 26px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 60px 24px 0;
    }

    .btn {
      width: auto;
      max-width: none;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 100px 36px 0;
    }

    ${Title} {
      font-size: 24px;
      line-height: 140%;
      margin: 0 0 30px;
    }

    ${Text}:last-of-type {
      margin-bottom: 30px;
    }

    .link-btn {
      display: inline-block;
    }

    .btn {
      margin-bottom: 0;
    }

    .collapse-action {
      margin: 20px auto 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 100px 54px 0;
      max-width: 1440px;
    }

    ${Title} {
      margin: 60px 0 40px;
    }

    ${Text}:last-of-type {
      margin-bottom: 60px;
    }
  }
`;

export const Container = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    max-width: 1122px;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  @media ${breakpoints.laptop} {
    width: calc(100% - 450px);
    height: max-content;
    margin: auto 0;
    max-width: 721px;
  }

  @media ${breakpoints.desktop} {
    width: calc(100% - 560px);
    margin: 0;
  }
`;
