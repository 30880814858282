import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakpoints';

export const Title = styled.h2`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 32px;
  }
`;

export const Slogan = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const CardPriceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: flex-end;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
  }
`;

export const CardPrice = styled.span`
  @media ${breakpoints.mobile} {
    color: var(--dark-text);
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const CardDuration = styled.span`
  @media ${breakpoints.mobile} {
    color: #333333;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CardImageWrapper = styled.div`
  @media ${breakpoints.mobile} {
    position: relative;
    height: 0;
    border: none;
    padding-top: 55%;
  }

  /* @media ${breakpoints.laptop} {
    margin-bottom: 20px;
  } */
`;

export const CardImage = styled.div`
  @media ${breakpoints.mobile} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: ${(props) => `url(${props.src}) no-repeat`};
    background-size: cover;
  }
`;

export const CardRating = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      align-items: center;
    }

    .icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }
  }
`;

export const CardRatingLabel = styled.span`
  @media ${breakpoints.mobile} {
    color: var(--primary-text);
    font-size: 14px;
    line-height: 12px;
    margin-right: 5px;
    margin-top: 0;
  }

  /* @media ${breakpoints.laptop} {
    font-size: 18px;
  } */
`;

export const CardSubtitle = styled.span`
  @media ${breakpoints.mobile} {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }

  @media ${breakpoints.tablet} {
    font-size: 20px;
  }
`;

export const CardQuantity = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #5d5d5d;
  }

  @media ${breakpoints.laptop} {
    font-size: 14px;
  }
`;

export const CardContainer = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      margin: ${({ marginBottom }) => (marginBottom ? '10px 0 42px' : '10px 0')};
    }
  }
`;

export const CardStats = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin: 0 0 10px;
  }
`;

export const Tags = styled.div`
  @media ${breakpoints.mobile} {
    margin-top: 8px;
    padding: 0 16px 25px;
  }
`;

export const Tag = styled.span`
  @media ${breakpoints.mobile} {
    display: inline-block;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #303133;
    padding: 4px 8px;
    border-radius: 12px;
    border: 1px solid rgb(6, 187, 155, 0.6);
    margin-right: 5px;
  }
`;

export const Card = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15);
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const CitiesCarListWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      padding: 0 15px 60px;
      width: 100%;
    }

    ${Title} {
      margin-bottom: 40px;
    }

    .box {
      margin: 24px 0;
      width: 100%;
    }

    && .item {
      padding: 0 0 16px;
    }

    .item:hover {
      opacity: 0.9;
    }

    .btn {
      width: 100%;
      max-width: 330px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 0 24px 60px;
    }

    .box {
      margin: 24px 0;
      width: calc(100% + 16px);
    }

    && .item {
      padding: 0 16px 16px 0;
    }

    .btn {
      width: auto;
      max-width: none;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 0 36px 100px;
    }

    .box {
      margin: 40px 0 10px;
      width: calc(100% + 32px);
    }

    && .item {
      padding: 0 36px 40px 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 0 54px 100px;
      max-width: 1440px;
    }
  }
`;

export const Owner = styled.div`
  /* & {
    display: flex;
    align-items: center;
    flex-grow: 2;
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .avatar img {
    margin-bottom: 0;
  } */
  & {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 16px;
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .avatar img {
    margin-bottom: 0;
  }
`;

export const OwnerSubtitle = styled.span`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #222;
`;
