import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { breakpoints } from '../../constants/breakpoints';

import { Title } from '../../styledComponents';
import { Box, Container, ThirteenWrapper, ImageWrapper, Subtitle, Text, Wrap } from './styles';

export default ({ title }) => {
  const isLaptop = useMediaQuery(breakpoints.laptop);
  return (
    <ThirteenWrapper>
      <Container>
        {!isLaptop && <Title className="title">{title}</Title>}
        <ImageWrapper />
        <Wrap>
          {isLaptop && <Title className="title">{title}</Title>}
          <Box>
            <Subtitle>Никаких залогов</Subtitle>
            <Text>
              Они больше не нужны: все поездки застрахованы, а круглосуточная служба поддержки и
              помощь на дорогах гарантируют спокойную и комфортную поездку
            </Text>
          </Box>
          <Box>
            <Subtitle>Все онлайн</Subtitle>
            <Text>
              Закажите доставку к своему дому и добавьте необходимые устройства к поездке: детское
              кресло, прицеп, багажник на крышу и многое другое. Никаких бумажных договоров и актов
            </Text>
          </Box>
          <Box>
            <Subtitle>Выбирайте по рейтингу и отзывам</Subtitle>
            <Text>
              Самые надежные и опытные владельцы проявят к вам заботу, внимание и предоставят лучшие
              цены. Выбирайте машину по рейтингу и отзывам других водителей
            </Text>
          </Box>
          <Box>
            <Subtitle>Бесплатная отмена бронирования</Subtitle>
            <Text>
              Отмените аренду бесплатно за 24 часа до поездки - ваши планы могут измениться и мы
              готовы к этому
            </Text>
          </Box>
        </Wrap>
      </Container>
    </ThirteenWrapper>
  );
};
