import React, { useEffect, useState } from 'react';
import stringReplacer from 'react-string-replace';

import { isBrowser } from '../../utils/isBrowser';
import Layout from '../layout';
import CitiesCarList from '../cities/citiesCarList';
import Partners from '../partners';
import Faq from '../faqNew';
import ReviewBlock from '../reviewBlock';
import ThirteenBlock from '../thirteenBlock';
import AppLinksBlock from '../homepage/appLinksBlock';
import SharingBlock from '../homepage/sharingBlock';
import SEO from '../seo';
import SearchWidgetWithout2GIS from '../homepage/searchWidget/WidgetWithout2GIS';
import { defaultStartRent, defaultEndRent } from '../../utils/date';
import { yandexTracks, googleTracks } from '../../seo';

import faqContent from '../../assets/data/faq-guest-content.json';
import sharingContent from '../../assets/data/sharing-content.json';

import { BackgroundWrapper, Colored, Company } from '../../styledComponents';

const sharingTitle = (
  <>
    <Colored color="#066BD6">Делитесь</Colored> своей машиной и зарабатывайте дополнительные деньги
    на платформе Getarent
  </>
);

const carListLink = '/app/search?location=38.971526%2C45.024359&address=Краснодар';

const cityQueries = 'location=38.971526%2C45.024359&address=Краснодар';

export default ({ pageContext = {} }) => {
  const {
    seoTitle,
    seoDescription,
    keyWord,
    pageTitle,
    pageDescription,
    breadcrumbs,
    listingTitle,
    listingDescription,
    reviewsTitle,
    mobileAppsTitle,
    searchQuery,
  } = pageContext;

  const [dateQueries, setDataQueries] = useState('');

  useEffect(() => {
    const dateStart = defaultStartRent();
    const encodedDateStart = encodeURIComponent(dateStart);
    const dateEnd = defaultEndRent(dateStart);
    const encodedDateEnd = encodeURIComponent(dateEnd);
    setDataQueries(`&dateStart=${encodedDateStart}&dateEnd=${encodedDateEnd}`);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  return (
    <Layout searchFilter="?location=38.971526%2C45.024359&address=Краснодар">
      <SEO title={seoTitle} description={seoDescription} />
      <BackgroundWrapper>
        <SearchWidgetWithout2GIS
          title={
            <>
              {stringReplacer(pageTitle, keyWord, (match) => (
                <Company>{match}</Company>
              ))}
            </>
          }
          text={pageDescription}
          breadcrumbs={breadcrumbs}
          initialAddress="Краснодар"
          filters={searchQuery}
          seo={[yandexTracks.krasnodarFindCar, googleTracks.krasnodarFindCar]}
        />
      </BackgroundWrapper>
      <BackgroundWrapper marginTop>
        <CitiesCarList
          title={listingTitle}
          text={listingDescription}
          link={`${carListLink}&${dateQueries}&${searchQuery}`}
          cityQueries={`${cityQueries}&${searchQuery}`}
          seo={[yandexTracks.krasnodarAllOffers, googleTracks.krasnodarAllOffers]}
        />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ReviewBlock titleText={reviewsTitle} />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <AppLinksBlock title={mobileAppsTitle} />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ThirteenBlock
          title={
            <>
              С <Colored color="#066bd6">Getarent</Colored> просто и удобно
            </>
          }
        />
      </BackgroundWrapper>
      <BackgroundWrapper background="#EDF1F7">
        <Faq
          content={faqContent}
          link="https://help.getarent.ru?utm_source=car-rental-income&utm_medium=pereiti-centr-pomoschi"
          title="Остались вопросы?"
          text="Найдите ответы на другие вопросы и узнайте больше о возможностях на сервисе"
          LTR
        />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <SharingBlock titleContent={sharingTitle} text={sharingContent} />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <Partners LTR />
      </BackgroundWrapper>
    </Layout>
  );
};
