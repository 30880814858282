import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { defaultStartRent, defaultEndRent, startRentWithDelay, endRent } from '../../../utils/date';
import { track } from '../../../seo';
import { SEARCH_CAR_URL } from '../../../constants/endpoints';

import { Colored } from '../../../styledComponents';
import {
  Tags,
  Tag,
  Title,
  Slogan,
  Card,
  CardDuration,
  CardImage,
  CardImageWrapper,
  CardContainer,
  CardSubtitle,
  CardRating,
  CardRatingLabel,
  CardPriceWrapper,
  CardPrice,
  CardQuantity,
  CitiesCarListWrapper,
  CardStats,
  // Owner,
  // OwnerSubtitle,
} from './styles';

const titleString = 'Забудьте про прокатные компании';

const sloganComponent = (
  <>
    Выберите автомобиль <Colored color="#0072F0">по рейтингу и отзывам</Colored> от других
    путешественников
  </>
);

const resultLimit = 9;

const carTags = {
  IMMEDIATE_BOOKING: 'Мгновенное бронирование',
  DELIVERY: 'Доставка',
  UNLIMITED_MILAGE: 'Пробег неограничен',
  AUTOMATIC_TRANSMISSION: 'Автомат',
};

const CitiesCarList = ({ title = titleString, text = sloganComponent, link, cityQueries, seo }) => {
  const [cars, setCars] = useState([]);

  // TODO move dates queries in parent component
  const getCars = async ({ dateStart, dateEnd }) => {
    try {
      const url = `${SEARCH_CAR_URL}?${cityQueries}&dateStart=${dateStart}:00.000Z&dateEnd=${dateEnd}:00.000Z&resultsLimit=${resultLimit}`;
      const response = await axios.get(url);
      const newCars = response.data.data;
      setCars(newCars);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const dateStart = defaultStartRent();
    const dateEnd = defaultEndRent(dateStart);
    getCars({ dateStart, dateEnd });
  }, []);

  return (
    <CitiesCarListWrapper>
      <Title className="title">{title}</Title>
      {text && <Slogan>{text}</Slogan>}
      <Grid className="box" container spacing={5}>
        {cars.map(
          ({
            uuid,
            brand,
            model,
            productionYear,
            photoUrl,
            rating,
            rentsQty,
            reviewsQty,
            rentPricePerDay,
            tags,
          }) => (
            <Grid key={uuid} item sm={12} md={6} lg={4} xl={4} className="item">
              <a title={`${brand} ${model}, ${productionYear}`} href={`/app/car/card/${uuid}`}>
                <Card>
                  <CardImageWrapper>
                    <CardImage src={photoUrl} />
                    <CardPriceWrapper>
                      <CardPrice>{rentPricePerDay} &#8381; </CardPrice>
                      <CardDuration>/сутки</CardDuration>
                    </CardPriceWrapper>
                  </CardImageWrapper>
                  <CardContainer marginBottom={!rentsQty && !reviewsQty}>
                    <CardSubtitle>{`${brand} ${model}, ${productionYear}`}</CardSubtitle>
                    {rating ? (
                      <CardRating>
                        <CardRatingLabel className="label">{rating.toFixed(1)}</CardRatingLabel>
                        <StarOutlinedIcon className="icon" />
                      </CardRating>
                    ) : null}
                  </CardContainer>
                  {rentsQty && reviewsQty ? (
                    <CardStats>
                      <CardQuantity>{`поездки: ${rentsQty}`}</CardQuantity>
                      <CardQuantity>{`отзывы: ${reviewsQty}`}</CardQuantity>
                    </CardStats>
                  ) : null}
                  {/* <Owner>
                    <Avatar alt={firstName} src={avatar} className="avatar" />
                    <OwnerSubtitle>{firstName}</OwnerSubtitle>
                  </Owner> */}
                  {/* <CardPriceWrapper>
                      <CardPrice>{rentPricePerDay} &#8381; </CardPrice>
                      <CardDuration>/сутки</CardDuration>
                  </CardPriceWrapper> */}
                  {tags.length > 0 ? (
                    <Tags>
                      {tags.map((tag) => (
                        <Tag>{carTags[tag]}</Tag>
                      ))}
                    </Tags>
                  ) : null}
                </Card>
              </a>
            </Grid>
          ),
        )}
      </Grid>
      <a
        href={link}
        title="Показать все предложения"
        onClick={() => {
          if (seo) {
            track(seo);
          }
        }}
      >
        <Button variant="contained" color="secondary" className="btn">
          Показать все предложения
        </Button>
      </a>
    </CitiesCarListWrapper>
  );
};

const KrasnodarListComponent = ({
  title = titleString,
  text = sloganComponent,
  cityQueries,
  rentDuration,
  immediatelyStart,
}) => {
  const [cars, setCars] = useState([]);

  // TODO move dates queries in parent component
  const getCars = async ({ dateStart, dateEnd }) => {
    try {
      const url = `${SEARCH_CAR_URL}?${cityQueries}&dateStart=${dateStart}:00.000Z&dateEnd=${dateEnd}:00.000Z`;
      const response = await axios.get(url);
      const newCars = response.data.data;
      setCars(newCars);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const dateStart = immediatelyStart ? defaultStartRent() : startRentWithDelay(30);
    const dateEnd = rentDuration ? endRent(dateStart, rentDuration) : defaultEndRent(dateStart);
    getCars({ dateStart, dateEnd });
  }, []);

  return (
    <CitiesCarListWrapper>
      <Title className="title">{title}</Title>
      {text && <Slogan>{text}</Slogan>}
      <Grid className="box" container spacing={5}>
        {cars.map(
          ({
            uuid,
            brand,
            model,
            productionYear,
            photoUrl,
            rating,
            rentsQty,
            reviewsQty,
            rentPricePerDay,
            tags,
          }) => {
            return (
              <Grid key={uuid} item sm={12} md={6} lg={4} xl={4} className="item">
                <a title={`${brand} ${model}, ${productionYear}`} href={`/app/car/card/${uuid}`}>
                  <Card>
                    <CardImageWrapper>
                      <CardImage src={photoUrl} />
                      <CardPriceWrapper>
                        <CardPrice>{rentPricePerDay} &#8381; </CardPrice>
                        <CardDuration>/сутки</CardDuration>
                      </CardPriceWrapper>
                    </CardImageWrapper>
                    <CardContainer marginBottom={!rentsQty && !reviewsQty}>
                      <CardSubtitle>{`${brand} ${model}, ${productionYear}`}</CardSubtitle>
                      {rating ? (
                        <CardRating>
                          <CardRatingLabel className="label">{rating.toFixed(1)}</CardRatingLabel>
                          <StarOutlinedIcon className="icon" />
                        </CardRating>
                      ) : null}
                    </CardContainer>
                    {rentsQty || reviewsQty ? (
                      <CardStats>
                        <CardQuantity>{`поездки: ${rentsQty}`}</CardQuantity>
                        <CardQuantity>{`отзывы: ${reviewsQty}`}</CardQuantity>
                      </CardStats>
                    ) : null}
                    {/* <Owner>
                      <Avatar alt={firstName} src={avatar} className="avatar" />
                      <OwnerSubtitle>{firstName}</OwnerSubtitle>
                    </Owner> */}
                    {/* <CardPriceWrapper>
                        <CardPrice>{rentPricePerDay} &#8381; </CardPrice>
                        <CardDuration>/сутки</CardDuration>
                    </CardPriceWrapper> */}
                    {tags.length > 0 ? (
                      <Tags>
                        {tags.map((tag) => (
                          <Tag>{carTags[tag]}</Tag>
                        ))}
                      </Tags>
                    ) : null}
                  </Card>
                </a>
              </Grid>
            );
          },
        )}
      </Grid>
    </CitiesCarListWrapper>
  );
};

export const KrasnodarList = memo(KrasnodarListComponent);

export default CitiesCarList;
