import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MobileImage from '../../../assets/images/sharing-mob.webp';
import LaptopImage from '../../../assets/images/sharing.webp';

import { Title } from '../../../styledComponents';

import { Container, SharingBlockWrapper, Text, Image, Wrapper } from './styles';

const link = './car-rental-income';

const SharingBlock = ({ titleContent, text }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SharingBlockWrapper>
      {isMobile ? (
        <>
          <Title>{titleContent}</Title>
          <Image mobileImage={MobileImage} laptopImage={LaptopImage} />
          {text.map(({ key, paragraph }) => (
            <Text key={key}>{paragraph}</Text>
          ))}
          <a href={link} title="Забронировать автомобиль" className="link-btn">
            <Button variant="contained" color="secondary" className="btn">
              Узнать подробности
            </Button>
          </a>
        </>
      ) : (
        <Container>
          <Image mobileImage={MobileImage} laptopImage={LaptopImage} />
          <Wrapper>
            <Title>{titleContent}</Title>
            {text.map(({ key, paragraph }) => (
              <Text key={key}>{paragraph}</Text>
            ))}
            <a href={link} title="Забронировать автомобиль" className="link-btn">
              <Button variant="contained" color="secondary" className="btn">
                Узнать подробности
              </Button>
            </a>
          </Wrapper>
        </Container>
      )}
    </SharingBlockWrapper>
  );
};

export default SharingBlock;
